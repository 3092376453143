import { Component, HostListener, ViewChild } from '@angular/core';

import { IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import packageJSON from '../../package.json';
import { NavigationEnd, Router } from '@angular/router';
import { ChangelogComponent } from './utils/modal/changelog/changelog.component';
import { environment } from 'src/environments/environment';
import { TestService } from './api/test.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  app_version:string = environment.environment+'-'+packageJSON.version;

  is_login:boolean = true;

  @HostListener('window:blur')
  onBlur() {
    // if(this.servicio.current_platform === 'browser') this.handlePaused();
  }

  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private modalController : ModalController,
    public servicio : TestService
  ) {
    this.platform.ready().then(() => {
      this.platform.pause.subscribe(() => {
        // this.handlePaused();
      });
    });
    this.router.url;
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        if(val.url == '/login') this.is_login = true;
        else this.is_login = false;
      }
    });
    this.initializeApp();
  }

  ngOnInit(){
    // this.routerOutlet.swipeGesture = false;
  }

  async changeLog(){
    const modal = await this.modalController.create({
      component: ChangelogComponent,
      cssClass: 'changelog-modal',
      showBackdrop: true,
      canDismiss: true,
    });
    await modal.present();
  }

  async handlePaused(){
    console.log('paused');
    if(this.servicio.is_test_finished) return;

    let date: Date = new Date();
    let fecha:string = date.getFullYear()+"-"+(date.getMonth()+1).toString().padStart(2,'0')+"-"+date.getDate().toString().padStart(2,'0');
    let hora:string  = date.getHours().toString().padStart(2,'0')+":"+date.getMinutes().toString().padStart(2,'0')+":"+date.getSeconds().toString().padStart(2,'0');

    let errores = {
      error_rojo: 0,
      error_no_toca: 0,
      error_sin_figura: 0
    }

    let unique_id = this.uniqueTestIdGenerator(this.servicio.usuario.rut, fecha, hora, [], 0, errores);

    let resultado = {
      rut: this.servicio.usuario.rut,
      fecha: fecha,
      hora: hora,
      foto_usuario: '',
      tiempo_reaccion: 0,
      cantidad_error: 0,
      cantidad_falso_positivo: 0,
      errores: errores,
      puntaje: 0,
      tiempo_reaccion_promedio: 0,
      resultado: 'TEST INCOMPLETO',
      traza: [],
      version: this.servicio.VERSION,
      id_test: unique_id,
      alcohol: this.servicio.alcohol
    }

    console.log('enviando test');
    await this.servicio.enviarTestSync(resultado);
    setTimeout(() => {
      this.router.navigate(['login']);
    }, 500);
  }

  uniqueTestIdGenerator(rut, fecha, hora,reaccion,fallos,errores){
    return rut.replace('-','')+fecha.split('-').join('')+hora.split(':').join('')+reaccion.join('')+fallos+errores.error_rojo+errores.error_no_toca+errores.error_sin_figura
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  volverAlLogin(){
    this.handlePaused();
  }
}
